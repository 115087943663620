import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import BgSm from '../../assets/image/background-hero1-sm.png'
import BgSm2 from '../../assets/image/background-hero2-sm.png'

export const Section = styled.section`
  background:url(${BgSm}), url(${BgSm2}) center right;
  background-repeat:no-repeat;
  background-size: 235px, 200px;
  background-position-x: 0px, calc(100% + 29px);
  background-position-y: 0px, 80px;
  min-height: 600px;

  @media (min-width: ${breakpoints.md}){
    background-image:none;
    min-height: auto;
  }

  @media (min-width: ${breakpoints.xxxl}){
    min-height: 760px;

    .gatsby-image-wrapper {
      max-width: 626px !important;
    }
  }
`

export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    width: 102px;

    @media (min-width: ${breakpoints.md}){
      width: 117px
    }

    @media (min-width: ${breakpoints.lg}){
      width: 159px
    }

    @media (min-width: ${breakpoints.xl}){
      width: 193px
    }
  }
`

export const ImgContainer = styled.img`
  position: absolute;
  width: 200px;
  object-fit: cover;
  right: 0px;
  top: 436px;

  @media (min-width: ${breakpoints.lg}){
    width: 250px;
    top: 399px;
  }

  @media (min-width: ${breakpoints.xl}){
    width: 335px;
    top: 335px;
  }

  @media (min-width: ${breakpoints.xxxl}){
    width: 408px;
    top: 524px;
  }
`
