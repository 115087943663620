import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Card } from './style'

const ConhecaAMecanica = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section id='como-funciona' className='bg-gray'>
      <div className='container py-5'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-32 lh-40 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fs-xl-48 lh-xl-60 text-orange--extra fw-600 mb-3'>
              O que é o concurso Supera PJ?
            </h2>

            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--400 mb-4'>
              Os empreendedores participantes enviaram um vídeo de no máximo 1 minuto e fizeram a inscrição no formulário desta página. Neste vídeo, eles contaram a história de suas empresas, falar dos desafios do dia a dia e como as soluções Inter Empresas + Mastercard tem ajudado a superá-los.
            </p>

            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--400 mb-4'>
              <span className='text-orange--extra fw-700'>Critérios de participação</span> <br />
              Os inscritos precisam ser clientes Inter e ter a conta de seu negócio aberta até o fim das inscrições. Podem participar todos os clientes MEIs e Inter Empresas, exceto pessoas jurídicas classificadas como sociedades anônimas.
            </p>

            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--400 mb-4'>
              <span className='text-orange--extra fw-700'>Critérios de seleção</span> <br />
              O vídeo deve ser criativo e envolvente e ter, no máximo, 1 minuto de duração. <br />
              O vídeo deve ter a participação de pelo menos 1 sócio da empresa. <br />
              É preciso contar como o Inter Empresas simplifica o dia a dia do empreendedor, ajudando a superar os desafios do negócio.<br />
              Os participantes devem seguir o Inter e o Inter Empresas no Instagram.<br />
              Confira todos os requisitos de participação no{' '}
              <a
                href='https://marketing.bancointer.com.br/empresas/documentos/regulamento-inter-supera-pj-concurso.pdf'
                target='_blank'
                rel='noreferrer'
                className='text-orange--extra fw-700'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    section_name: 'Como vai funcionar?',
                    element_action: 'click button',
                    element_name: 'regulamento',
                    redirect_url: 'https://marketing.bancointer.com.br/empresas/documentos/regulamento-inter-supera-pj-concurso.pdf',
                   })
                }}
              >
                regulamento
              </a>.
            </p>
          </div>

          <div className='d-md-flex'>
            <Card className='mb-3 mr-md-4'>
              <p className='fs-24 lh-30 fs-lg-24 lh-lg-30 fs-xl-32 lh-xl-40 text-white font-sora mb-3'>
                1ª FASE
              </p>
              <p className='fs-16 lh-20 fs-lg-16 lh-lg-20 fs-xl-20 lh-xl-25 text-orange--extra fw-600 font-sora mb-3'>
                5/10 a 24/10
              </p>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white'>
                Os participantes terão 20 dias para prepararem seus vídeos e se inscreverem na nossa LP.
              </p>
            </Card>

            <Card className='mb-3 mr-md-4'>
              <p className='fs-24 lh-30 fs-lg-24 lh-lg-30 fs-xl-32 lh-xl-40 text-white font-sora mb-3'>
                2ª FASE
              </p>
              <p className='fs-16 lh-20 fs-lg-16 lh-lg-20 fs-xl-20 lh-xl-25 text-orange--extra fw-600 font-sora mb-3'>
                25/10 a 3/11
              </p>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white'>
                Os vídeos enviados serão submetidos a uma comissão julgadora que selecionará 10 finalistas do concurso.
              </p>
            </Card>

            <Card className='mb-4'>
              <p className='fs-24 lh-30 fs-lg-24 lh-lg-30 fs-xl-32 lh-xl-40 text-white font-sora mb-3'>
                3ª FASE
              </p>
              <p className='fs-16 lh-20 fs-lg-16 lh-lg-20 fs-xl-20 lh-xl-25 text-orange--extra fw-600 font-sora mb-3'>
                6/11 a 16/11
              </p>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white'>
                Os 10 selecionados pelo júri técnico participarão de uma votação na LP Inter Empresas + Mastercard.
              </p>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white'>As três empresas mais votadas pelo público neste site serão as ganhadoras.</p>
            </Card>
          </div>

          <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--400 mb-4'>
            <span className='text-orange--extra fw-700'>
              Períodos
            </span><br />
            Inscrição - 5/10 a 24/10 <br />
            Apuração - 25/10 a 3/11 <br />
            Votação - 6/11 a 16/11 <br />
            Divulgação dos vencedores - 20/11
          </p>
        </div>
      </div>
    </section>
  )
}

export default ConhecaAMecanica
