import React from 'react'

import Img from 'gatsby-image'

import usePageQuery from '../../pageQuery'

import { Section, ImgContainer, ImgSize } from './style'
import BgMd from '../../assets/image/background-hero2-md.png'
import ScrollTo from 'src/components/ScrollTo'
import { ImgWebp } from 'src/styles/imgWebp'

const Hero = () => {
  const data = usePageQuery()

  return (
    <Section className='bg-white d-flex align-items-end align-items-md-center'>
      <div className='container py-5'>
        <ImgContainer src={BgMd} className='d-none d-md-block order-md-last' />
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 d-none d-md-block order-md-last'>
            <ImgWebp
              breakPointsSize={{ md: '329px', lg: '456px', xl: '536px' }}
              src=' https://central-imagens.bancointer.com.br/images-without-small-versions/hero-concurso/image.webp'
              alt='Empreendedora pensativa enquanto usa seu computador.'
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <ImgSize>
              <Img fluid={data.logosConcursoSuperaPj.fluid} alt='Logos' />
            </ImgSize>

            <h1 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-48 lh-lg-60 fs-xl-72 lh-xl-90 text-grayscale--500 fw-600 mb-3 mt-3'>
              Concurso <span className='d-block'>Supera PJ</span>
            </h1>

            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--500 fw-600 mt-3 mb-3 mb-md-4'>
              Parabéns aos vencedores!
            </p>

            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--500 mt-3 mb-3 mb-md-4'>
              Chegou a hora: confira quem foram as três empresas que inspiraram o Brasil e venceram o Concurso Supera PJ.
            </p>

            <ScrollTo
              to='#votacao'
              section='dobra_01'
              sectionName='Concurso Supera PJ '
              elementName='Conhecer os vencedores'
              styles='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-grayscale--100 text-none'
            >
              Conhecer os vencedores
            </ScrollTo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
