
interface IBackgroundImages {
  [index: string]: string;
}

export const bgImages: IBackgroundImages = {
  bwd: 'https://img.youtube.com/vi/CCOD0DNRsrk/mqdefault.jpg',
  chocolateLab: 'https://img.youtube.com/vi/zK3XMnBRqNY/mqdefault.jpg',
  cortex: 'https://img.youtube.com/vi/GbKxVLd0K48/mqdefault.jpg',
  dpersan: 'https://img.youtube.com/vi/8XSRoNkNFBs/mqdefault.jpg',
  idear: 'https://img.youtube.com/vi/Vw98i_ec_vc/mqdefault.jpg',
  moa: 'https://img.youtube.com/vi/YSiNYVvyjw4/mqdefault.jpg',
  modesto: 'https://img.youtube.com/vi/6NBwUw8eLp8/mqdefault.jpg',
  nordeste: 'https://img.youtube.com/vi/_CPHCXNHrjE/mqdefault.jpg',
  selo: 'https://img.youtube.com/vi/QCZD_eRP8xc/mqdefault.jpg',
  vaiViver: 'https://img.youtube.com/vi/Bso-vKDC2mc/mqdefault.jpg',
}
