import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import { TrackData } from '@interco/track'

import pageContext from './pageContext.json'

import Hero from './_sections/hero/_index'
import Votacao from './_sections/votacao/_index'
import ConfiraOsPremios from './_sections/confira-os-premios/_index'
import ConhecaAMecanica from './_sections/conheca-a-mecanica/_index'
import Faq from './_sections/faq/_index'

import { Wrapper } from './style'

const IndiqueEGanhe = () => {
  TrackData.initOrUpdate({
    runtimeEnv: 'production',
    projectName: 'votacao-supera-pj',
  })

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <Votacao />
        <ConhecaAMecanica />
        <ConfiraOsPremios />
        <Faq faq={pageContext.structuredData.faq} />
      </Layout>
    </Wrapper>
  )
}

export default IndiqueEGanhe
