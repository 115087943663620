import React, { useState } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Modal } from 'src/components/Modal'
import VideoModal from './components/VideoModal/_index'
// import ModalVotacao from './components/ModalVotacao/_index'
import VideoDataJSON from './components/assets/data/videoData.json'
import { bgImages } from './components/assets/images/_index'
import { Section, Card, Player } from './style'

import Reproducao from '../../assets/image/reproduçao.png'

interface IVideoProps {
  id?: number;
  title: string;
  image?: string;
  link?: string;
}

const Votacao = () => {
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()
  // const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ isPopupOpen, setIsPopupOpen ] = useState(false)
  const [ modalVideoData, setModalVideoData ] = useState<IVideoProps>({
    id: 1,
    title: '',
    link: '',
  })

  const handleClick = ({ title, link }: IVideoProps) => {
    setModalVideoData({
      title,
      link,
    })
    setIsPopupOpen(true)
  }

  // const handleClickVote = ({ title, id }: IVideoProps) => {
  //   setModalVideoData({
  //     title,
  //     id,
  //   })
  // }

  // const votacaoModal = domReady && (
  //   <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
  //     <ModalVotacao closeModal={() => setIsModalOpen(false)} modalVideoData={modalVideoData} />
  //   </Modal>
  // )

  const PopupExitPage = domReady && (
    <Modal isModalOpen={isPopupOpen} setIsModalOpen={setIsPopupOpen} locationToRender={document.body}>
      <VideoModal closeModal={() => setIsPopupOpen(false)} setPopup={setIsPopupOpen} modalVideoData={modalVideoData} />
    </Modal>
  )

  return (
    <Section id='votacao'>
      {PopupExitPage}
      {/* {votacaoModal} */}
      <div className='container py-5'>
        <div className='row justify-content-lg-center'>

          <div className='col-lg-9'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-48 lh-xl-60 text-orange--extra text-center fw-600 mb-3'>
              Histórias <span className='d-block d-md-inline'>vencedoras</span>
            </h2>
            <p className='fs-14 lh-17 fs-md-18 lh-md-22 text-white text-center mb-4 pb-md-3'>
              Conheça a história das três empresas que, com muita coragem, superam desafios todos os dias e, além de contar com o Inter Empresas nessa jornada, inspiraram todo o Brasil e foram as mais votadas. Acompanhe os próximos passos pelo Instagram
              <a
                className='text-orange--extra fw-600'
                href='https://www.instagram.com/interempresas/'
                target='_blanck'
              >
                {' '}@interempresas
              </a>.
            </p>
          </div>

          <div className='col-12'>
            <div className='d-flex flex-wrap justify-content-center card-gap'>
              {VideoDataJSON.map((item: IVideoProps, index: number) => (
                <div key={index}>
                  <div className=''>
                    <Card className='bg-white'>
                      <Player
                        image={bgImages[item.image]}
                        className='d-flex align-items-end p-2'
                        onClick={() => {
                          handleClick({
                            title: item.title,
                            link: item.link,
                          })
                          sendDatalayerEvent({
                            section: 'dobra_02',
                            section_name: 'Escolha sua história favorita',
                            element_name: item.title,
                            element_action: 'click video',
                          })
                        }}
                      >
                        <img src={Reproducao} />
                      </Player>

                      <p className='fs-16 lh-20 fs-lg-16 lh-lg-19 fs-xl-16 lh-xl-19 text-grayscale--500 fw-600 mt-3 mb-md-4 text-center' dangerouslySetInnerHTML={{ __html: item.title }} />

                      {/* <button
                        className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-grayscale--100 text-none mw-100'
                        onClick={() => {
                          setIsModalOpen(true)
                          handleClickVote({
                            title: item.title,
                            id: item.id,
                          })
                          sendDatalayerEvent({
                            section: 'dobra_02',
                            section_name: 'Escolha sua história favorita',
                            element_name: 'Quero votar neste!',
                            element_action: 'click button',
                          })
                        }}
                      >
                        Quero votar neste!
                      </button> */}
                    </Card>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Votacao
