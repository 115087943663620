import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

import BgMD from '../../assets/image/background-votacao-md.png'
import BgLG from '../../assets/image/background-votacao-lg.png'
import BgXL from '../../assets/image/background-votacao-xl.png'

interface IPlayerProps {
  image: string;
}

export const Section = styled.section`
  background-color: #07605B;

  a {
    cursor: pointer;
  }

  .card-gap {
    gap: 24px;
  }

  @media (min-width: ${breakpoints.md}){
    background-image: url(${BgMD});
    background-repeat: no-repeat;
    height: 810px;
  }

  @media (min-width: ${breakpoints.lg}){
    background-image: url(${BgLG});
    background-repeat: no-repeat;
    height: 861px;
  }

  @media (min-width: ${breakpoints.xl}){
    background-image: url(${BgXL});
    background-repeat: no-repeat;
    height: 670px;
  }

  @media (min-width: ${breakpoints.xxl}){
    background-size: cover;
  }
`

export const Card = styled.div`
  height: auto;
  border-radius: 8px;
  padding: 17px 20px;
  cursor: pointer;

  @media (min-width: ${breakpoints.md}){
    width: 329px;
  }

  @media (min-width: ${breakpoints.lg}){
    width: 359px;
  }

  @media (min-width: ${breakpoints.xl}){
    width: 359px;
  }
`

export const Player = styled.div<IPlayerProps>`
  background-image: ${(props: IPlayerProps) => `url(${props.image})`};
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 154px;

  @media (min-width: ${breakpoints.md}){
    height: 162px;
  }

  @media (min-width: ${breakpoints.lg}){
    height: 177px;
  }

  img {
    object-fit: fill;

    @media (min-width: ${breakpoints.md}){
      width: 256px;
      height: 29px;
    }

    @media (min-width: ${breakpoints.lg}){
      width: 280px;
      height: 32px;
    }
  }
`
