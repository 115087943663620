import React from 'react'

import { Section, Card } from './style'

const ConfiraOsPremios = () => {
  return (
    <Section className=''>
      <div className='container py-5'>
        <div className='row'>
          <div className='col-12 col-lg-5'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fs-xl-48 lh-xl-60 text-orange--extra fw-600 mb-3'>
              Confira os prêmios
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white mb-4'>
              Os prêmios do concurso “Supera PJ” podem mudar o futuro da sua empresa, e fazer com que superar os desafios
              do dia a dia seja muito mais simples.
            </p>
          </div>

          <div className='col-lg-6 offset-lg-1'>
            <Card className='bg-white mb-3'>
              <h3 className='fs-14 lh-17 fs-lg-20 lh-lg-25 fs-xl-20 lh-xl-25 text-orange--extra fw-700 mb-3'>
                Jornada Empreendedora no Brasil
              </h3>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-16 lh-xl-19 text-grayscale--400'>
                Os vencedores irão aproveitar uma verdadeira imersão preparada pelo Sebrae, com:<br />
                <ul className='ml-4'>
                  <li className='mt-1'>Consultoria financeira, de gestão de vendas e de marketing digital.</li>
                  <li className='mt-1'>Curso Empretec, o principal programa de formação de empreendedores do mundo criado pela Organização das Nações Unidas (ONU).</li>
                  <li className='mt-1'>Participação no evento Reload, em Belo Horizonte.</li>
                  <li className='mt-1'>Participação no evento Startup Summit, em Santa Catarina.</li>
                  <li className='mt-1'>Participação no evento Web Summit, no Rio de Janeiro.</li>
                  <li className='mt-1'>Participação em um evento do Sebrae focado no segmento do vencedor.</li>
                </ul>
              </p>
            </Card>

            <Card className='bg-white'>
              <h3 className='fs-14 lh-17 fs-lg-20 lh-lg-25 fs-xl-20 lh-xl-25 text-orange--extra fw-700 mb-3'>Imersão no Inter</h3>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-16 lh-xl-19 text-grayscale--400'>
                Os vencedores do concurso serão convidados para um dia na sede do Inter, em Belo Horizonte, onde terão uma conversa enriquecedora com executivos da empresa e com especialistas de áreas estratégicas para seus segmentos.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ConfiraOsPremios
