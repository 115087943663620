import React, { MouseEvent } from 'react'
import YoutubeVideo from 'src/components/YoutubeVideo'

// Images
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'

// Styles
import {
  Container,
  CloseButton,
  DefaultPopUp,
 } from './style'
import useWidth from 'src/hooks/window/useWidth'

 interface IVideoProps {
  title: string;
  link: string;
 }

type Popup = {
  closeModal: () => void;
  setPopup?: (state: boolean) => void;
  child?: React.ReactNode;
  modalVideoData: IVideoProps;
}

function VideoModal ({ closeModal, setPopup, child, modalVideoData }: Popup) {
  const Width = useWidth()

  const handleClick = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    closeModal()
    setPopup && setPopup(false)
  }

  const closeButton = true && (
    <CloseButton className='border-0 bg-transparent' onClick={handleClick}>
      <Close width='20' height='20' color='orange--extra' />
    </CloseButton>
  )

  const setBreakpointHeight = () => {
    if (Width < 768) {
      return '100%'
    }

    if (Width >= 768 && Width < 1024) {
      return '234px'
    }

    if (Width >= 1024 && Width < 1440) {
      return '350px'
    }

    if (Width >= 1440) {
      return '450px'
    }

    return ''
  }

  return (
    <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
      <Container className='container bg-white'>
        {closeButton}
        {
          child ? child
          : (
            <DefaultPopUp>
              <div className='row align-items-center rounded-3'>
                <YoutubeVideo
                  src={modalVideoData.link}
                  width='100%'
                  height={setBreakpointHeight()}
                  className='rounded-3'
                />
              </div>
              <p className='fs-18 lh-22 fw-700 text-green--500 font-sora mt-3 mb-0' dangerouslySetInnerHTML={{ __html: modalVideoData.title }} />
            </DefaultPopUp>
          )
        }
      </Container>
    </div>
  )
}

export default VideoModal
