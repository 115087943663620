import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Card = styled.div`
  background-color: #07605B;
  height: auto;
  border-radius: 16px;
  padding: 24px;

  @media (min-width: ${breakpoints.md}){
    height: 297px;
  }

  @media (min-width: ${breakpoints.lg}){

  }
`
