import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bannerHeroConcursoSuperaPj: imageSharp(fluid: {originalName: { regex: "/banner-hero-concurso-supera-pj/" }}) {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      logosConcursoSuperaPj: imageSharp(fluid: {originalName: { regex: "/logos-concurso-supera-pj/" }}) {
        fluid(maxWidth: 196, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
