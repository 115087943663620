import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import BgMD from './assets/images/background-video-md.png'

export const Container = styled.div`
  width: 338px;
  min-height: 269px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  position: relative;

  @media ${device.tablet} {
    width: fit-content;
    display: block;
    padding: 31px 31px 31px 31px;
    min-height: 350px;
    width: 480px;

    background-image: url(${BgMD});
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
  }

  @media ${device.desktopLG} {
    min-height: 450px;
    width: 740px;
  }

  @media ${device.desktopXL} {
    min-height: 574px;
    width: 740px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 3px;
  top: 0;
  z-index: 10;
  outline: none !important;
`

export const DefaultPopUp = styled.div``
