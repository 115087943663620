import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-color: #07605B;
`

export const Card = styled.div`
  height: auto;
  border-radius: 8px;
  padding: 17px 20px;

  @media (min-width: ${breakpoints.lg}){

  }
`
