import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import FAQ from 'src/components/Faq/index'

interface IFaqSection {
  faq: {
    question: string;
    answer: string;
  }[];
}

const FaqSection = ({ faq }: IFaqSection) => {
  return (
    <SectionComponent
      id='perguntas-frequentes'
      SectionStyles='bg-white'
    >
      <div className='col-12'>
        <h2 className='fs-24 lh-30 fs-md-24 lh-md-30 fs-xl-32 lh-xl-40 text-grayscale--500 mb-md-4 text-center font-sora'>
          Dúvidas frequentes
        </h2>
      </div>
      <FAQ answerData={faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} searchBg='#F5F6FA' />
    </SectionComponent>
  )
}

export default FaqSection
